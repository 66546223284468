import React from 'react';

import { ReactComponent as SmartManSys } from './smartmansys.svg';
import './loading.scss';

const Loading = () => {
  return (
    <div className="loading">
      <SmartManSys width={100} height={40} />
      <div className="loading__progress">
        <div className="loading__progress__bar" />
      </div>
    </div>
  );
};

export default Loading;
