import React, { useContext, useState } from 'react';
import isHotkey from 'is-hotkey';

import { AuthContext } from '../../auth-context';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-solid-svg-icons';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';

import NavItems from './nav-items';

import './navbar.scss';

const isEnter = isHotkey('enter');

const Navbar = () => {
  const { t } = useTranslation('navbar');
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const signOut = () => {
    document.cookie =
      document.cookie.split(';').find((c) => c.startsWith('_gk_session=')) + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.forms.logoutForm.submit();
  };

  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-smartmansys">
      <Link to="/" className="navbar-brand">
        <FontAwesomeIcon icon={faTools} /> Norman
      </Link>
      <button className="navbar-toggler" type="button" data-target="#navbarNav" onClick={() => setOpen(!open)}>
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className={`navbar-collapse${open ? ' navbar-collapse--show bg-smartmansys' : ''}`}
        id="navbarNav"
        onClick={() => setOpen(false)}
        onKeyUp={(e) => isEnter(e) && setOpen(false)}
        role="button"
        tabIndex="-1"
      >
        <ul className="navbar-nav mr-auto">
          <NavItems className="d-lg-none" />
          <li className="nav-item d-lg-none">
            <a href="#logout" onClick={signOut} className="nav-link text-white">
              <FontAwesomeIcon icon={faSignOut} /> {t('logOut')}
              <form action={`${process.env.REACT_APP_GATEKEEPER_URL}/sessions`} method="DELETE" />
            </a>
          </li>
        </ul>

        <ul className="navbar-nav">
          <li className="nav-item d-none d-lg-block">
            <a className="nav-link text-white" href={`${process.env.REACT_APP_GATEKEEPER_URL}/user/profile`}>
              <img
                alt={user?.firstname}
                src={`${process.env.REACT_APP_GATEKEEPER_URL}/avatars/${user?.md5Hash}`}
                className="rounded-circle"
                width="30"
                height="30"
              />{' '}
              {user?.firstName}
            </a>
          </li>
          <li className="nav-item d-none d-lg-block">
            <a href="#logout" onClick={signOut} className="nav-link text-white">
              <FontAwesomeIcon icon={faSignOut} /> {t('signOut')}
              <form id="logoutForm" action={`${process.env.REACT_APP_GATEKEEPER_URL}/session`} method="POST">
                <input type="hidden" name="_method" value="delete" />
              </form>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
